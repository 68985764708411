<template lang="html">
  <div class="container" id="text-page">
    <h1 class="page-title txt-black">Cambios y Devoluciones</h1>

    <h5>FORMAS DE ENVÍO Y TIEMPOS DE ENTREGA</h5>
    <h6>QUE MÉTODOS DE ENVIÓ UTILIZAN?</h6>
    <p>
      En Lovely Nails México utilizamos diferentes empresas de paquetería que trabajan a nivel nacional como DHL, Fedex, Estafeta, Redpack, 99 Minutos, entre otras. Nuestro sistema asigna el transportista y genera la guía automáticamente al momento de hacer la compra basándose en que paquetería ofrece el mejor tiempo de entrega para determinada ubicación, siempre buscando dar el mejor servicio para el cliente.
    </p>

    <br />
    <h6>¿CUÁNTO CUESTA EL ENVÍO?</h6>
    <p>
      Lovely Nails México te ofrece métodos de envío con precios diferentes basados en el peso y tamaño de tu pedido, consulta el valor del envío de tu pedido en tu carrito de compras antes de ir a pagar.
    </p>

    <br />
    <h6>¿CUÁNTO TARDA UN ENVÍO EN LLEGAR?</h6>
    <p>
      Nuestra prioridad es entregar tu pedido lo antes posible. Regularmente el envío estándar los pedidos tardan de 1 a 7 días hábiles, dependiendo del lugar de la república a donde es enviado, (Nuestro sistema elige de forma inteligente la mejor paquetería dependiendo de tu Código Postal).
    </p>
    <p>
      Tan pronto tu pedido sea recolectado por la paquetería de nuestro almacén, recibirás una notificación vía WhatsApp con tu número de guía para que puedas rastrear tu pedido en cada paso de tu proceso de entrega y estar pendiente de su llegada.
    </p>

    <br />
    <h6>¿PUEDO RECIBIR MI PEDIDO EN UN HORARIO ESPECÍFICO?</h6>
    <p>
      Debido a que tu pedido será enviado por una empresa de paquetería, no podemos garantizar un horario especifico ya que estas empresas manejan entregas en “horario abierto” (de 9am – 8pm). Al momento que despachemos tu paquete, te haremos llegar tu número de guía para que puedas rastrear el paquete en cada paso de su ruta. Si la mayor parte del día no estas en casa, puedes solicitar el envío tipo “Ocurre” proporcionándonos la dirección de la sucursal de paquetería de tu conveniencia al momento de realizar tu compra.
    </p>

    <br />
    <h5>DEVOLUCIONES Y REEMBOLSOS</h5>
    <p>
      En Lovely Nails México nuestro compromiso es que todos los clientes tengan la mejor experiencia de compra! Si tuviste algún problema con tu pedido, a continuación te explicaremos como funciona el proceso de devolución:<br />
      Para iniciar el proceso de devolución, el producto deberá cumplir con las siguientes condiciones:
    </p>
    <ul>
      <li>
        Ser Devuelto en un plazo de 2 días a partir de que recibas el paquete.
      </li>
      <li>
        Se debe encontrar en la misma condición en el que lo recibiste.
      </li>
      <li>
        No estar usado y contar con sus etiquetas y nota de compra.
      </li>
      <li>
        La devolución deberá ser aprobada por nuestro equipo de Customer service basado en las evidencias presentadas.
      </li>
      <li>
        En caso de recibir tu producto con algún daño es necesario reportarlo en un plazo no mayor a 24hrs, en caso contrario no podremos aplicar la garantía o seguro.
      </li>
      <li>
        Si tu paquete figura como entregado pero no lo has recibido es necesario reportarlo con nuestro equipo en un plazo no mayor a 24hrs
      </li>
    </ul>
    <p>
      Para iniciar el proceso de devolución en línea, por favor contáctanos vía WhatsApp para abrir un ticket de soporte y generar una guía de devolución.
    </p>
    <p>
      *Una vez que hayas completado el anterior proceso te haremos llegar la guía en un lapso de 3-5 días hábiles. Si el producto cumple con todos los requisitos que te mencionamos anteriormente, se aprobara por nuestro departamento de customer service tu devolución y te enviaremos la guía vía correo electrónico.
    </p>

    <br />
    <h5>EN QUE CASOS NO APLICA UNA DEVOLUCION O REEMBOLSO?</h5>
    <p>
      A continuación te detallamos los motivos cuando una compra NO aplica para ser reembolsada o para realizar un cambio de producto:
    </p>
    <ul>
      <li>
        Si el empaque del producto llega dañado (caja golpeada y/o rota, el celofán o plástico que envuelve al producto viene desprendido o roto entre otros) pero el producto en si viene en perfectas condiciones (sin daños visibles, en buen estado y funcionando). Esto debido a que aunque el envío va asegurado las paqueterías no se hacen responsables por el manejo del paquete durante su trayecto por lo que si el producto no tiene ningún daño el seguro no aplica.<br />
        * Entiéndase “Empaque” por la caja o bolsa original de fabrica con la que viene el producto.
      </li>
      <li>
        Si al momento de realizar tu pedido los datos del domicilio los ingresaste de manera incorrecta y tu producto fue entregado en otra dirección.
      </li>
      <li>Si al realizar tu pedido seleccionaste un producto o versión diferente al que buscabas.</li>
      <li>
        i existiera alguna anomalía con la entrega y no es reportado dentro de las primeras 24hrs el seguro del envío no se podrá hacer válido.
      </li>
    </ul>
    <p>
      Si tienes alguna duda sobre estas condiciones, por favor contáctanos vía WhatsApp y con gusto te asesoraremos.
    </p>

    <br />
    <h5>PROCEDIMIENTO PARA ENVIAR UNA DEVOLUCIÓN</h5>
    <p>
      Una vez que recibas la aprobación de nuestro customer service para realizar la devolución, debes seguir los siguientes pasos:
    </p>
    <ul>
      <li>
        Empacar el producto que vas a devolver en una caja completamente sellada y bien protegida.
      </li>
      <li>
        Pegar la guía impresa que te mandamos en la caja.
      </li>
      <li>
        Enviar tu paquete a nuestra dirección comercial.
      </li>
    </ul>
    <p>
      Tan pronto recibamos tu devolución y nuestro equipo de calidad corrobore que cumpla con las condiciones que mencionamos anteriormente, te daremos un CUPON DE DESCUENTO por el monto acordado aplicable para tu siguiente compra. Este cupón tiene un vencimiento de 30 días. El cupón NO es acumulable y NO aplica con otras promociones.
    </p>
    <p>
      En cuanto recibas la aprobación para devolución en tu correo electrónico, tendrás 1 día para enviar el paquete en la sucursal de la mensajería acordada o pedir la recolección. De lo contrario, la aprobación caducara automáticamente y deberás comunicarte de nuevo con nuestro customer service si deseas retomar el proceso de devolución.
    </p>

    <br />
    <h5>REEMBOLSOS Y CAMBIOS</h5>
    <h6>REEMBOLSOS</h6>
    <p>
      Una vez cumpliendo todas las condiciones y pasos que te mencionamos anteriormente, Te reembolsaremos a tu cuenta a por medio de un CUPON DE DESCUENTO el total del valor del producto menos los gastos del primer envío. Nosotros cubriremos los gastos de envío de la devolución.
    </p>
    <p>
      Cuando recibamos tu paquete de devolución, éste pasa por un proceso administrativo/calidad que tarda 5-10 días hábiles, después de ese lapso de tiempo automáticamente te enviaremos el cupón de descuento y hacer uso de el en nuestro sitio web. Recuerda que, al usar tu CUPON DE DESCUENTO en tu siguiente compra, se cobrará el costo del envío regularmente.
    </p>

    <br />
    <h5>CAMBIOS EN LAS POLITICAS</h5>
    <p>
      Las presentes políticas son sujetas a cambio sin previo aviso. En caso de existir modificaciones, se informará vía medios de comunicación de Lovely Nails: Página de Internet.
    </p>
  </div>
</template>

<script>
export default {
}
</script>
