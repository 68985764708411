<template lang="html">
  <div id="express-page">
    <section class="container main-section">
      <div class="row">
        <div class="col-12 col-top">
          <h2 class="title">Compra express</h2>
        </div>

        <div class="col-lg-12 col-filters">
          <b-form class="row" @submit="onSubmit">
            <div class="col-md-6 col-lg-3">
              <b-form-group class="custom-f-group-s1" label="Buscar">
                <b-form-input
                  v-model="form.email"
                  type="text"
                  placeholder="Buscar distribuidor"
                  required
                ></b-form-input>
              </b-form-group>
            </div>

            <div class="col-md-6 col-lg-3 mt-3 mt-md-0">
              <b-form-group class="custom-f-group-s1" label="Categorías">
                <b-form-select v-model="form.state">
                  <b-form-select-option :value="null">Todas las categorías</b-form-select-option>
                  <b-form-select-option value="1">Aguascalientes</b-form-select-option>
                  <b-form-select-option value="2">CDMX</b-form-select-option>
                  <b-form-select-option value="3">Guanajuato</b-form-select-option>
                  <b-form-select-option value="4">Jalisco</b-form-select-option>
                  <b-form-select-option value="5">Zacatecas</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </div>

            <div class="col-lg-6 col-pagination box-pagination-s1">
              <nav aria-label="navigation">
                <ul class="pagination">
                  <li class="page-item">
                    <a class="page-link" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                      <span class="sr-only">Previous</span>
                    </a>
                  </li>
                  <li class="page-item"><a class="page-link">1</a></li>
                  <li class="page-item"><a class="page-link">2</a></li>
                  <li class="page-item"><a class="page-link">3</a></li>
                  <li class="page-item">
                    <a class="page-link" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                      <span class="sr-only">Next</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </b-form>
        </div>

        <div class="col-lg-12 col-results">
          <div class="box-table">
            <div class="row row-top">
              <div class="col col-cell c-1">Imagen</div>
              <div class="col col-cell c-2">Nombre</div>
              <div class="col col-cell c-3">Categorías</div>
              <div class="col col-cell c-4">Precio</div>
              <div class="col col-cell c-5">Comprar</div>
            </div>

            <div class="row row-body" v-for="x in 30">
              <div class="col col-cell c-1">
                <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url(public/images/pages/products/product-2.jpg)' }"></div>
              </div>
              <div class="col col-cell c-2">Coleccion Pink</div>
              <div class="col col-cell c-3">Herramientas & Otros</div>
              <div class="col col-cell c-4">
                $299.00
              </div>
              <div class="col col-cell c-5">
                <CartComponent :product="{ id: 1, name: 'example' }"></CartComponent>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6 ml-auto col-pagination box-pagination-s1">
          <nav aria-label="navigation">
            <ul class="pagination">
              <li class="page-item">
                <a class="page-link" aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                  <span class="sr-only">Previous</span>
                </a>
              </li>
              <li class="page-item"><a class="page-link">1</a></li>
              <li class="page-item"><a class="page-link">2</a></li>
              <li class="page-item"><a class="page-link">3</a></li>
              <li class="page-item">
                <a class="page-link" aria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                  <span class="sr-only">Next</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import CartComponent from './cart-component.vue';
export default {
  components: {
    CartComponent
  },

  data() {
    return {
      form: {
        state: null,
      },
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
    },
  }

}
</script>
