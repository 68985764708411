<template lang="html">
  <div id="cart-page" class="d-flex account-page">
    <div class="container main-box">

      <div class="row">
        <div class="col-12 px-2">
          <h1 class="mb-3 page-title">Mi carrito</h1>
        </div>

        <div class="col-lg px-2 col-menu">
          <div class="white-box">
            <div class="box-menu py-2">
              <p class="item">
                <router-link class="btn-menu" to="/cart"><i class="fas fa-shopping-cart"></i> Mi carrito</router-link>
              </p>
              <p class="item">
                <router-link class="btn-menu" to="/mis-favoritos"><i class="fas fa-heart"></i> Mis favoritos</router-link>
              </p>
              <!-- <p class="item">
                <router-link class="btn-menu" to="/historial-de-compras"><i class="fas fa-history"></i> Historial de compras</router-link>
              </p> -->
            </div>
          </div>
        </div>

        <div class="col-lg px-2 col-cart-data">

          <div class="white-box">

            <!-- Paso 1 (Lista de productos agregados a carrito) -->
            <div class="col-12 pt-3 product-list-table" v-if="$route.params.step !== 'envio' && $route.params.step !== 'pago' && $route.params.step !== 'finalizado'">
              <div class="col-12 px-0">
                <h5>Productos agregados al carrito</h5>

                <h6 class="mt-4 pb-4 f-w-400 text-warning" v-if="!form.products.length"><i class="fas fa-shopping-basket"></i> Ningun producto ha sido agregado al carrito</h6>
              </div>

              <div class="row row-products" v-for="(product, index) in form.products">
                <div class="col col-image">
                  <img :src="product.imageUrl">
                </div>

                <div class="col col-info">
                  <h5 class="txt-name">
                    <router-link target="_blank" :to="'/producto/'+product.id">{{ product.name }}</router-link>
                  </h5>

                  <div class="row mx-0 align-items-center">
                    <div class="d-flex">
                      <h6 class="txt-sm text-muted mr-2">SKU: {{ product.sku }}</h6>
                      <h6 class="txt-sm text-muted mr-2" v-if="product.color"> Color: {{ product.color }} </h6>
                      <h6 class="txt-sm text-muted" v-if="product.size"> Talla: {{ product.size }} </h6>
                    </div>
                    <!-- <div class="d-flex">
                      <div class="box-color-opt opt-sm">
                        <a class="color" title="Color x" v-bind:style="{ backgroundColor: '#e63003' }">{{ product.color }}</a>
                        <a class="color" :title="product.color">{{ product.color }}</a>
                        <a class="color" :title="product.size">{{ product.size }}</a>
                      </div>
                    </div> -->
                  </div>
                  <hr class="mt-0 mb-1" />

                  <router-link class="link-sm text-muted" target="_blank" :to="'/producto/'+product.id">Ver producto</router-link> <span class="mx-2">|</span> <a class="link-sm text-danger" @click="removeCart(index)"><i class="far fa-trash-alt"></i> Quitar</a>
                </div>

                <div class="col col-price">
                  <p>$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(product.price) }} MXN</p>
                </div>
                <div class="col col-num">
                  <b-form-input v-model="product.quantity" type="number" min="1" :max="product.stock" placeholder="0" />
                </div>
              </div>
            </div>
            <!-- paso 1, Fin -->

            <!-- Paso 2 (Envio, Datos del usuario y datos de envío) -->
            <form id="form-cliente" class="col-12 py-4 px-4 user-data" v-if="$route.params.step == 'envio'" @submit.prevent="validar()">
            <!-- <div class="col-12 py-4 px-4 user-data" v-if="$route.params.step == 'envio'"> -->
              <h6>Datos de personales</h6>
              <hr class="c-hr" />

              <div class="custom-input _p">
                <label class="col-form-label pt-0">Nombre:</label>
                <p class="txt-simple" v-if="$root.logged">{{ form.user.name }}</p>
                <b-form-input id="user-name" v-else type="text" v-model="form.user.name" size="sm" required placeholder="Nombre" />
              </div>

              <div class="custom-input _p">
                <label class="col-form-label pt-0">Apellidos:</label>
                <p class="txt-simple" v-if="$root.logged" v-text="form.user.lastname"></p>
                <b-form-input id="user-lastname" v-else type="text" v-model="form.user.lastname" size="sm" required placeholder="Apellidos" />
              </div>

              <div class="custom-input _p">
                <label class="col-form-label pt-0">Correo electrónico:</label>
                <p class="txt-simple" v-if="$root.logged" v-text="form.user.email"></p>
                <b-form-input id="user-email" v-else type="email" v-model="form.user.email" size="sm" required placeholder="Correo electrónico" />
              </div>

              <div class="custom-input _p">
                <label class="col-form-label pt-0">Teléfono:</label>
                <b-form-input id="user-phone" type="text" v-model="form.user.phone" size="sm" required placeholder="Teléfono" maxlength="10" />
              </div>


              <h6 class="mt-5">Información de envío</h6>
              <hr class="c-hr" />

              <div class="row mx-0 no-gutters">
                <div class="col-md-7 col-lg-6">
                  <b-form-group class="custom-input" label="Calle:" label-for="i-calle">
                    <b-form-input id="i-calle" type="text" v-model="form.user.street" size="sm" placeholder="Calle donde vive" required/>
                  </b-form-group>
                </div>

                <div class="col-md pl-md-3">
                  <b-form-group class="custom-input" label="Numero exterior:" label-for="i-num">
                    <b-form-input id="i-num" type="text" v-model="form.user.num_ext" size="sm" required/>
                  </b-form-group>
                </div>

                <div class="col-md pl-md-3">
                  <b-form-group class="custom-input" label="Numero interior:" label-for="i-num">
                    <b-form-input id="i-num" type="text" v-model="form.user.num_int" size="sm"/>
                  </b-form-group>
                </div>

                <div class="col-md-12 col-lg-12">
                  <b-form-group class="custom-input" label="Referencia:" label-for="i-reference">
                    <b-form-input id="i-reference" type="text" v-model="form.user.reference" size="sm" placeholder="Referencia" required/>
                  </b-form-group>
                </div>
              </div>

              <div class="row mx-0 no-gutters">
                <div class="col-md-8">
                  <b-form-group class="custom-input" label="Colonia:" label-for="i-colonia">
                    <b-form-input id="i-colonia" type="text" v-model="form.user.neighborhood" size="sm" required placeholder="Escriba su colonia" />
                  </b-form-group>
                </div>

                <div class="col-md pl-md-3">
                  <b-form-group class="custom-input" label="Código postal:" label-for="i-cp">
                    <b-form-input id="i-cp" type="text" v-model="form.user.zipcode" size="sm" maxlength="5" required placeholder="Escriba su código postal" />
                  </b-form-group>
                </div>
              </div>


              <div class="row mx-0 no-gutters">
                <div class="col-md-6 pr-md-3">
                  <b-form-group class="custom-input" label="Estado:" label-for="i-estado">
                      <b-form-select id="festado" v-model="form.user.state_id" :options="states"  value-field="id" text-field="name" size="sm" required></b-form-select>
                  </b-form-group>
                </div>

                <div class="col-md-6">
                  <b-form-group class="custom-input" label="Ciudad:" label-for="i-ciudad">
                      <b-form-select id="festado" v-model="form.user.town_id" :options="towns"  value-field="id" text-field="name" size="sm" required></b-form-select>
                  </b-form-group>
                </div>
              </div>

              <!-- <div class="row mx-0 no-gutters py-3">
                  <b-form-checkbox id="checkbox-1" v-model="facturacion.factura" name="checkbox-1"><strong class="f-w-500" style="position:relative; top: 2px;">Solicitar Factura</strong></b-form-checkbox>
              </div> -->
              <template v-if="facturacion.factura">
                <h6 class="mt-4">Datos de facturación</h6>
                <hr class="c-hr"/>

                <div class="row mx-0 no-gutters">
                  <div class="col-md-6 pr-md-3">
                    <b-form-group class="custom-input" label="Nombre o razón social:" label-for="f-nombre">
                      <b-form-input id="f-nombre" type="text" v-model="facturacion.nombre" size="sm" required/>
                    </b-form-group>
                  </div>

                  <div class="col-md-6">
                    <b-form-group class="custom-input" label="Rfc:" label-for="f-rfc">
                      <b-form-input id="f-rfc" type="text" v-model="facturacion.rfc" size="sm" required/>
                    </b-form-group>
                  </div>
                </div>

                <div class="row mx-0 no-gutters">
                  <div class="col-md-12 col-lg-12">
                    <b-form-group class="custom-input" label="Cuenta con la que se realizará el pago:" label-for="f-cuente">
                      <b-form-input id="f-cuenta" type="text" v-model="facturacion.cuenta" size="sm" required />
                    </b-form-group>
                  </div>
                </div>

                <div class="row mx-0 no-gutters">

                  <div class="col-md-6 pr-md-3">
                    <b-form-group class="custom-input" label="Tipo de comprobante:" label-for="fcomprobante">
                      <b-form-select id="fcomprobante" v-model="facturacion.tipo" size="sm" required>
                        <option :value="null">Seleccione una opcion</option>
                        <option value="Adquisición de nercancias">Adquisición de mercancias</option>
                        <option value="Devoluciones, descuentos o bonificaciones">Devoluciones, descuentos o bonificaciones</option>
                        <option value="Gastos en general">Gastos en general</option>
                        <option value="Construcciones">Construcciones</option>
                        <option value="Mobilario y equipo de oficina por inversiones">Mobilario y equipo de oficina por inversiones</option>
                        <option value="Equipo de transporte">Equipo de transporte</option>
                        <option value="Equipo de computo y accesorios">Equipo de computo y accesorios</option>
                        <option value="Dados, troquetes, moldes, matrices y herramental">Dados, troquetes, moldes, matrices y herramental</option>
                        <option value="Comunicaciones telefónicas">Comunicaciones telefónicas</option>
                        <option value="Comunicaciones satelitales">Comunicaciones satelitales</option>
                        <option value="Otra maquinaria y equipo">Otra maquinaria y equipo</option>
                        <option value="Por definir">Por definir</option>
                      </b-form-select>
                    </b-form-group>
                  </div>

                  <div class="col-md-6">
                    <b-form-group class="custom-input" label="Correo" label-for="fcorreo">
                      <b-form-input id="fcorreo" v-model="facturacion.correo" size="sm" type="email" required></b-form-input>
                    </b-form-group>
                  </div>
                </div>

                <div class="row mx-0 no-gutters">
                      <div class="col-md-6 pr-md-3">
                        <b-form-group class="custom-input" label="Dirección" label-for="fdireccion">
                          <b-form-input id="fdireccion" v-model="facturacion.direccion" size="sm" type="text" required></b-form-input>
                        </b-form-group>
                      </div>
                      <div class="col-md-3 pr-md-3">
                        <b-form-group class="custom-input" label="Numero Exterior" label-for="fext">
                          <b-form-input id="fext" v-model="facturacion.no_ext" size="sm" type="text" required></b-form-input>
                        </b-form-group>
                      </div>
                      <div class="col-md-3">
                        <b-form-group class="custom-input" label="Numero Interior" label-for="fint">
                          <b-form-input id="fint" v-model="facturacion.no_int" size="sm" type="text"></b-form-input>
                        </b-form-group>
                      </div>
                  </div>
                  <div class="row mx-0 no-gutters">
                      <div class="col-md-6 pr-md-3">
                        <b-form-group class="custom-input" label="Telefono" label-for="iftelefono">
                          <b-form-input id="ftelefono" v-model="facturacion.telefono" size="sm" type="text" required></b-form-input>
                        </b-form-group>
                      </div>
                      <div class="col-md-6">
                        <b-form-group class="custom-input" label="Codigo Postal" label-for="fcp">
                          <b-form-input id="fcp" v-model="facturacion.cp" size="sm" type="text" required></b-form-input>
                        </b-form-group>
                      </div>
                  </div>
                  <div class="row mx-0 no-gutters">

                      <div class="col-md-4 pr-md-3">
                        <b-form-group class="custom-input" label="Estado" label-for="festado">
                          <b-form-select id="festado" v-model="facturacion.estado" :options="states"  value-field="id" text-field="name" size="sm" required @change="getFciudades"></b-form-select>
                        </b-form-group>
                      </div>
                      <div class="col-md-4 pr-md-3">
                        <b-form-group class="custom-input" label="Ciudad" label-for="fciudad">
                            <b-form-select id="festado" v-model="facturacion.ciudad" :options="fciudades" value-field="id" text-field="name" size="sm" required></b-form-select>
                        </b-form-group>
                      </div>
                      <div class="col-md-4">
                        <b-form-group class="custom-input" label="Colonia" label-for="fcolonia">
                          <b-form-input id="fcolonia" v-model="facturacion.colonia" size="sm" type="text" required></b-form-input>
                        </b-form-group>
                      </div>
                  </div>
              </template>

            </form>
            <!-- Paso 2, fin  -->

            <!-- Paso 3 (pago) -->
            <div class="col-12 py-4 px-4 user-data" v-if="$route.params.step == 'pago'">
              <h6>Información de pago</h6>
              <hr class="c-hr" />

              <div class="mb-2 custom-input _p text-center">
                <label class="col-form-label pt-0">Seleccione una forma de pago:</label>
              </div>

              <div class="row mb-4 justify-content-center payment-options-container">
                <!-- <div class="col col-box" @click="changePaymentMe('tarjetas')">
                  <div class="box" v-bind:class="{ active: form.tipoPago == 'tarjetas' }">
                    <h6>Tarjeta de crédito o débito</h6>
                    <p class="icons">
                      <i class="fab fa-cc-visa"></i>
                      <i class="fab fa-cc-mastercard"></i>
                    </p>
                  </div>
                </div> -->

                <div class="col col-box" @click="changePaymentMe('transferencia')">
                  <div class="box" v-bind:class="{ active: form.tipoPago == 'transferencia' }">
                    <h6>Deposito o transferencia<br />bancaria</h6>
                    <p class="icons">
                      <i class="fas fa-money-check-alt"></i>
                      <i class="fas fa-money-bill"></i>
                    </p>
                  </div>
                </div>

                <!-- <div class="col col-box" @click="changePaymentMe('paypal')">
                  <div class="box" v-bind:class="{ active: form.tipoPago == 'paypal' }">
                    <h6>Pago a traves de Paypal</h6>
                    <p class="icons">
                      <i class="fab fa-paypal"></i>
                    </p>
                  </div>
                </div> -->
              </div>

              <!-- Pago 1, con tarjetas -->
              <div class="row mx-0 no-gutters" v-if="form.tipoPago == 'tarjetas'">
                <div class="col-12">
                    <label class="mt-3">Detalles de tarjeta:</label>
                    <div id="cardElement"></div>
                    <small class="form text text-muted" id="cardErrors" role="alert"></small>
                </div>
              </div>
              <!--  -->

              <!-- Pago 2, con transferencia bancaria -->
              <div class="col-12 text-center" v-if="form.tipoPago == 'transferencia'">
                <img class="mb-1" :src="depositdata.imageUrl" width="100">
                <p>{{depositdata.banco}}</p>
                <p>{{depositdata.beneficiario}}</p>
                <p><strong>Cuenta bancaria:</strong> {{depositdata.numero_cuenta}}</p>
                <p><strong>Clabe Interbancaria:</strong> {{depositdata.clabe}}</p>


                <hr />
                <p>
                  Favor de enviarnos el comprobante de pago al siguiente correo: <br />
                  <strong>pagos@lovelynails.mx</strong>
                </p>
              </div>
              <!--  -->

              <!-- Pago 3, con paypal -->
              <div class="col-12 text-center" v-if="form.tipoPago == 'paypal'">
                <p>Depósito por medio de paypal.</p>

                <hr />
                <p>
                  Favor de enviarnos el comprobante de pago al siguiente correo: <br />
                  <strong>pagos@lovelynails.mx</strong>
                </p>
              </div>
              <!--  -->
            </div>
            <!-- -->

            <!-- Paso 4 (finalizado) -->
            <div class="col-12 py-4 px-4 col-finish" v-if="$route.params.step == 'finalizado'">
              <p class="mb-3">
                <!-- <i class="far fa-check-circle icon-check"></i> -->
                <!-- <i class="fal fa-check-circle icon-check"></i> -->
                <success-component></success-component>
              </p>
              <h4 class="mb-3 f-w-600">¡Pedido realizado exitosamente!</h4>

              <h5>Número de pedido: <strong class="txt-orange">XI000{{ modal.orderId }}</strong></h5>
              <h5>Por favor de enviar su comprobante de pago.</h5>

              <h6 class="mt-3" v-html="modal.msg"></h6>

              <p class="mt-2">
                <router-link to="/"><strong><i class="fas fa-home"></i> Ir al Inicio</strong></router-link>
              </p>
            </div>
        </div>

          <div class="row mx-0 py-3 px-lg-0 no-gutters row-total" v-if="$route.params.step != 'finalizado'">
            <div class="col-lg-6">
              <router-link class="btn btn-outline-danger btn-sm btn-action" :to="'/cart/' + form.prev" v-if="form.prev != null">Anterior</router-link>
              <router-link class="btn btn-outline-danger btn-sm btn-action" :to="'/cart/' + form.next" v-if="form.products.length && form.next && form.next != 'pago'">Siguiente</router-link>
              <input type="submit" form="form-cliente" class="btn btn-outline-danger btn-sm btn-action" value="Siguiente" v-if="form.next == 'pago'">
              <button class="btn btn-danger btn-sm btn-action" v-if="form.paying && form.tipoPago == 'transferencia'" @click="pagar()" :disabled="desactivar || order_id">Confirmar pedido</button>
              <button class="btn btn-danger btn-sm btn-action" v-if="form.paying && form.tipoPago == 'tarjetas'" @click="pagar()" :disabled="desactivar || order_id || form.payment_intent_id">Pagar</button>
            </div>

            <div class="col-lg-6 text-right">
              <h6><span>Subtotal: </span> ${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(subtotal) }}</h6>
              <h6><span>IVA: </span> ${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(iva) }}</h6>
              <h6><span>Costo de envío: </span>
                <strong v-if="form.envio > 0">${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(form.envio) }}</strong>
                <strong v-if="form.envio == 0"> Por Calcular</strong>
              </h6>
              <h6 v-if="form.envio > 0"><span>Tiempo de envío: </span> 3 a 5 días hábiles</h6>
              <h5><span>Total a pagar: </span> ${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(total) }}</h5>
            </div>
          </div>
        </div>
      </div>

      <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block"  ref="modal">
        <div class="fa-3x" v-if="modal.icon== ''"><i class="fas fa-spinner fa-pulse"></i></div><br/>
        <div v-html="modal.msg"></div>
        <div class="col-12 text-center" style="padding-top: 20px;" v-if="modal.icon == 'success'">
          <b-button class="btn btn-success" slot="button" v-on:click.prevent="$refs.modal.close(); $router.push('/')">Regresar a Home <i class="color-white fas fa-home"></i></b-button>
        </div>
      </sweet-modal>

    </div>
  </div>
</template>

<script>
import successComponent from '../shared/success-component.vue';
export default {
  data(){
    return{
      form: {
        products: [],

        user: {
          "email": '',
          "name": '',
          "lastname": '',
          "phone": '',
          "street": '',
          "num_ext": '',
          "num_int": '',
          "neighborhood":'',
          "zipcode": null,
          "state_id": null,
          "town_id": null,
          "reference": ''
        },

        prev: '',
        next: '',
        paying: false,

        tipoPago: '',
        subtotal: 0,
        iva: 0,
        total: 0,
        envio: 0,
        payment_intent_id: null
      },

      facturacion:{
        required:false,
        nombre:'',
        rfc:'',
        tipo:null,
        cuenta:'',
        correo:'',
        direccion:'',
        no_ext:'',
        no_int:'',
        calles:'',
        telefono:'',
        cp:'',
        colonia:'',
        ciudad:null,
        estado:null,
      },

      modal:{
        msg:'',
        icon:'',
        block:false,
      },

      userLogin: false,
      states: [],
      towns: [],
      fciudades: [],
      order_id: null,

      stripe: {},
      elements: {},
      cardElement: {},

      desactivar: false,
      depositdata:{}
    }
  },

  computed: {
    subtotal() {
     var subtotal = this.form.products.reduce(function (subtotal, item) {
        return subtotal + (item.price * item.quantity)
      },0);

      return this.form.subtotal = subtotal - (subtotal * 0.16);
    },

    iva() {
     var subtotal = this.form.products.reduce(function (subtotal, item) {
        return subtotal + (item.price * item.quantity)
      },0);

      return this.form.iva = subtotal * 0.16;
    },

    total() {
      var subtotal = this.form.products.reduce(function (subtotal, item) {
        return subtotal +  (item.price  * item.quantity)
      },0);

      var grandTotal  = parseFloat(subtotal) + parseFloat(this.form.envio);
      return this.form.total = grandTotal;
    }
  },

  methods: {
    changePaymentMe(tipo){
      this.form.tipoPago = tipo;
      if(tipo == "tarjetas"){
        setTimeout(this.loadStripe, 50);
      }
    },

    validar: async function(){
        if(this.form.products.length){
            this.$router.push({ path: '/cart/'+this.form.next });

        }else{
            alert('No hay productos en el carrito');
        }
    },

    pagar(){
      if(this.form.tipoPago == "transferencia"){
        this.desactivar = true;
        this.createOrder();
      }

      if(this.form.tipoPago == "tarjetas"){
        this.desactivar = true;
        this.payStripe();
      }
    },

    getSteps(){
      var step = this.$route.params.step;
      this.form.paying = false;

      if(step == 'pago'){
            if(this.form.user.email && this.form.user.name && this.form.user.phone){
              this.form.prev = 'envio';
              this.form.next = null;
              this.form.paying = true;
              this.cotizador();
            }else{
                this.$router.push({ path: '/cart/envio' });
            }
      }
      else if(step == 'envio'){ // Paso 2
        this.form.prev = '';
        this.form.next = 'pago';
      }
      else{
        this.form.prev = null;

        if(this.form.products.length){
          this.form.next = 'envio';
        }
      }
    },

    getStates(){
      axios.get(tools.url('/api/states')).then((response)=>{
        this.states = response.data;
      }).catch((error)=>{
         console.log(error);
      });
    },

    getTowns(){
      if(this.form.user.state_id){
        axios.get(tools.url('/api/towns/' + this.form.user.state_id)).then((response)=>{
          this.towns = response.data;
        }).catch((error)=>{
          console.log(error);
        });
      }
    },

    getFciudades(){
      axios.get(tools.url('/api/towns/' + this.facturacion.estado)).then((response)=>{
        this.fciudades = response.data;
      }).catch((error)=>{
        console.log(error);
      });
    },

    cotizador(){
      axios.post(tools.url('/api/cotizador'),{ products:this.form.products, zipcode:this.form.user.zipcode }).then((response)=>{
        if(response.data.total){
            this.form.envio = (parseFloat(response.data.total) * 1.16) * 1.038 + 3;
          }
      }).catch((error)=>{
        console.log(error);
      });
    },

    getInfo(){
      this.form.user = {
        //Datos personales
        id: this.$root.user.id,
        name: this.$root.user.name,
        lastname: this.$root.user.lastname,
        email: this.$root.user.email,
        phone: this.$root.user.phone,
        street: this.$root.user.address.street,
        num_ext: this.$root.user.address.num_ext,
        num_int: this.$root.user.address.num_int,
        neighborhood: this.$root.user.address.neighborhood,
        zipcode: this.$root.user.address.zipcode,
        state_id: this.$root.user.address.state_id,
        town_id: this.$root.user.address.town_id,
      };

      this.getTowns();
    },

    getCart: function(){
      var storage = localStorage.getItem('cart');
      if(storage != null){
        var products = JSON.parse(storage);
        axios.post(tools.url('/api/cart'),{ products:products, zipcode: this.form.user.zipcode}).then((response)=>{
          this.form.products = response.data.cart;
          this.localStorageCart();
          this.getSteps();

        }).catch((error)=>{
          console.log(error);
          this.getSteps();
          localStorage.removeItem('cart');
          this.$root.cartCount = 0;
        });
      }
    },

    removeCart: function(index) {
        this.$delete(this.form.products, index)
        this.updateCart();
    },

    updateCart(){
        this.localStorageCart();
        this.getCart();
        if(this.form.user.zipcode){
            this.cotizador();
        }
    },

    localStorageCart(){
        var cart = [];
        this.form.products.forEach(function(val, index, array){
          var info = { id:val.id, quantity: val.quantity };
          cart.push(info);
        });
        localStorage.setItem("cart",JSON.stringify(cart));
        this.$root.cartCount = cart.length;
    },

    createOrder: function(){
        this.modal.block = true;
        this.modal.icon = '';
        this.modal.msg = 'Procesando, por favor espere...';
        this.$refs.modal.open();

        axios.post(tools.url('/api/order/store'),{ order:this.form, products:this.form.products, user:this.form.user, userLogin:this.$root.logged, factura:this.facturacion }).then((response)=>{
            if(response.data.type === "success"){
                this.$refs.modal.close();
                this.order_id = response.data.order_id;
                localStorage.removeItem('cart');
                this.$root.cartCount = 0;
                //mensaje
                this.modal.orderId = response.data.order_id;
                this.modal.icon = "success";
                this.modal.msg = null;
                this.modal.block = false;
                this.$router.push({ path: '/cart/finalizado' });

            }else{
                this.$refs.modal.close();
                alert(response.data.error);
            }
        }).catch((error)=>{
            console.log(error);
            this.modal.icon="error";
            this.modal.msg = "Fallo al realizar el pedido., Verifique su internet y recargue la página";
            this.modal.block = false;
            this.$refs.modal.open();
            this.desactivar = false;
        });

    },

    loadStripe(){
      //sandbox key
      this.stripe = Stripe('pk_live_51JPuYkGpPyDXa7VmuhKBvNXo8CWLdLTRcunP7BVBJo8S8qkAlVPQf0VeQ5VGz01iHeW5ar09Wgala7CnmLXUsIoe007JST9kYN');
      this.elements = this.stripe.elements({locale: 'es'});
      this.cardElement = this.elements.create('card', {hidePostalCode: true});

      this.cardElement.mount("#cardElement");
      this.cardElement.addEventListener('change', ({ error }) => {
          const displayError = document.getElementById('cardErrors');
          if (error) {
            displayError.textContent = error.message;
          } else {
            displayError.textContent = '';
          }
      });
    },

    payStripe(){
      this.modal.block = true;
      this.modal.icon = '';
      this.modal.msg = 'Validando el metodo de pago. por favor espere...';
      this.$refs.modal.open();

      this.stripe.createPaymentMethod({
          type: 'card',
          card: this.cardElement,
          billing_details: { name: this.form.user.name+' '+this.form.user.lastname, email: this.form.user.email }
        }).then((result)=>{
          if (result.error) {
            alert('Ocurrió un error con su tarjeta');
            this.$refs.modal.close();
            this.desactivar = false;
          } else {
            const data  = { payment_method_id: result.paymentMethod.id, total: this.form.total, name: this.form.name, email: this.form.email };
            axios.post(tools.url('/api/stripe/Installments'),data).then((response)=>{
                this.form.payment_intent_id = response.data.intent_id;
                this.pagarStripe();
              });
          }
      });
    },

    pagarStripe: function(){
      this.modal.block = true;
      this.modal.icon = '';
      this.modal.msg = 'Generando su pedido por favor espere...';
      this.$refs.modal.open();

      axios.post(tools.url('/api/stripe/pay'),{  order:this.form, products:this.form.products, user:this.form.user, userLogin:this.$root.logged, factura:this.facturacion }).then((response)=>{
          if(response.data.type === "success"){
            this.order_id = response.data.order_id;
            localStorage.removeItem('cart');
            this.$root.cartCount = 0;
            //mensaje
            this.modal.icon = "success";
            this.modal.msg = "TU PEDIDO HA SIDO EXITOSO!<br/> Número de pedido: XI000"+response.data.order_id+".";
            this.modal.block = true;
            this.$refs.modal.open();

          }else{
            this.modal.icon = "error";
            this.modal.msg = response.data.message;
            this.modal.block = false;
            this.desactivar = false;
          }
      }).catch((error)=>{
        this.$refs.modal.close();
        alert("ERROR: No se pudo realizar el pedido.");
        this.desactivar = false;
      });
    },

    getDepositdata(){
      axios.get(tools.url('/api/depositdata')).then((response)=>{
        this.depositdata = response.data;
      }).catch((error)=>{

      });
    },

  },

  beforeMount(){
    this.getStates();
    this.getCart();

    if(this.$root.logged){
      this.getInfo();
    }

    this.getSteps();
    this.getDepositdata();
  },

  watch: {
    '$route.params.step': function () {
      this.getSteps();
    },

    'form.user.state_id':function(val){
      if(val && !isNaN(val)){
        this.getTowns();
      }
    },

    /*'form.user.zipcode':function(val){
      if(val && !isNaN(val) && val.length == 5){
        this.cotizador();
      }
    },*/

    "$root.logged": function(v) {
      if(v == true){
        this.getInfo();
      }
    },
  },

  components: {
    successComponent
  },
}
</script>

<style>
.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
</style>
