<template lang="html">
  <div class="container" id="text-page">
    <h1 class="page-title txt-black">Política de privacidad</h1>

    <br />
    <h5>Quiénes somos</h5>
    <p>
      La dirección de nuestra web es: https://lovelynails.mx.
    </p>

    <br />
    <h5>Comentarios</h5>
    <p>
      Cuando los visitantes dejan comentarios en la web, recopilamos los datos que se muestran en el formulario de comentarios, así como la dirección IP del visitante y la cadena de agentes de usuario del navegador para ayudar a la detección de spam.
    </p>
    <p>
      Una cadena anónima creada a partir de tu dirección de correo electrónico (también llamada hash) puede ser proporcionada al servicio de Gravatar para ver si la estás usando. La política de privacidad del servicio Gravatar está disponible aquí: https://automattic.com/privacy/. Después de la aprobación de tu comentario, la imagen de tu perfil es visible para el público en el contexto de tu comentario.
    </p>

    <br />
    <h5>Medios</h5>
    <p>
      Si subes imágenes a la web, deberías evitar subir imágenes con datos de ubicación (GPS EXIF) incluidos. Los visitantes de la web pueden descargar y extraer cualquier dato de ubicación de las imágenes de la web.
    </p>

    <br />
    <h5>Cookies</h5>
    <p>
      Si dejas un comentario en nuestro sitio puedes elegir guardar tu nombre, dirección de correo electrónico y web en cookies. Esto es para tu comodidad, para que no tengas que volver a rellenar tus datos cuando dejes otro comentario. Estas cookies tendrán una duración de un año.
    </p>
    <p>
      Si tienes una cuenta y te conectas a este sitio, instalaremos una cookie temporal para determinar si tu navegador acepta cookies. Esta cookie no contiene datos personales y se elimina al cerrar el navegador.
    </p>
    <p>
      Cuando accedas, también instalaremos varias cookies para guardar tu información de acceso y tus opciones de visualización de pantalla. Las cookies de acceso duran dos días, y las cookies de opciones de pantalla duran un año. Si seleccionas «Recuérdarme», tu acceso perdurará durante dos semanas. Si sales de tu cuenta, las cookies de acceso se eliminarán.
    </p>
    <p>
      Si editas o publicas un artículo se guardará una cookie adicional en tu navegador. Esta cookie no incluye datos personales y simplemente indica el ID del artículo que acabas de editar. Caduca después de 1 día.
    </p>

    <br />
    <h5>Contenido incrustado de otros sitios web</h5>
    <p>
      Los artículos de este sitio pueden incluir contenido incrustado (por ejemplo, vídeos, imágenes, artículos, etc.). El contenido incrustado de otras webs se comporta exactamente de la misma manera que si el visitante hubiera visitado la otra web.
    </p>
    <p>
      Estas web pueden recopilar datos sobre ti, utilizar cookies, incrustar un seguimiento adicional de terceros, y supervisar tu interacción con ese contenido incrustado, incluido el seguimiento de tu interacción con el contenido incrustado si tienes una cuenta y estás conectado a esa web.
    </p>

    <br />
    <h5>Con quién compartimos tus datos</h5>
    <p>
      Si solicitas un restablecimiento de contraseña, tu dirección IP será incluida en el correo electrónico de restablecimiento.
    </p>

    <br />
    <h5>Cuánto tiempo conservamos tus datos</h5>
    <p>
      Si dejas un comentario, el comentario y sus metadatos se conservan indefinidamente. Esto es para que podamos reconocer y aprobar comentarios sucesivos automáticamente, en lugar de mantenerlos en una cola de moderación.
    </p>
    <p>
      De los usuarios que se registran en nuestra web (si los hay), también almacenamos la información personal que proporcionan en su perfil de usuario. Todos los usuarios pueden ver, editar o eliminar su información personal en cualquier momento (excepto que no pueden cambiar su nombre de usuario). Los administradores de la web también pueden ver y editar esa información.
    </p>

    <br />
    <h5>Qué derechos tienes sobre tus datos</h5>
    <p>
      Si tienes una cuenta o has dejado comentarios en esta web, puedes solicitar recibir un archivo de exportación de los datos personales que tenemos sobre ti, incluyendo cualquier dato que nos hayas proporcionado. También puedes solicitar que eliminemos cualquier dato personal que tengamos sobre ti. Esto no incluye ningún dato que estemos obligados a conservar con fines administrativos, legales o de seguridad.
    </p>

    <br />
    <h5>Dónde se envían tus datos</h5>
    <p>
      Los comentarios de los visitantes puede que los revise un servicio de detección automática de spam.
    </p>

    <br />
    <br />
    <h3 class="text-center">Política de Privacidad de la Tienda LovelyNails</h3>
    <p>
      Bienvenido a LovelyNails, tu destino de confianza para accesorios para uñas e insumos para manicura. En LovelyNails, valoramos y respetamos la privacidad de nuestros clientes. Esta Política de Privacidad describe cómo recopilamos, utilizamos y protegemos la información personal que nos proporcionas.
    </p>

    <br />
    <h5>Información que Recopilamos</h5>
    <p><strong>Información de Contacto:</strong></p>
    <ul>
      <li>Nombre</li>
      <li>Dirección de correo electrónico</li>
      <li>Número de teléfono</li>
    </ul>

    <br />
    <p><strong>Información de Pago: </strong></p>
    <ul>
      <li>Datos de tarjeta de crédito/débito</li>
      <li>Información de facturación</li>
    </ul>

    <br />
    <p><strong>Información de Envío: </strong></p>
    <ul>
      <li>Dirección de envío</li>
    </ul>

    <br />
    <h5>Uso de la Información</h5>
    <p>
      Utilizamos la información recopilada para:
    </p>
    <ul>
      <li>Procesar tus pedidos y facilitar la entrega de productos.</li>
      <li>Enviar actualizaciones sobre el estado de tus pedidos.</li>
      <li>Mejorar nuestros productos y servicios.</li>
      <li>Personalizar tu experiencia de compra.</li>
    </ul>

    <br />
    <h5>Seguridad de la Información</h5>
    <p>
      Nos comprometemos a proteger la seguridad de tu información personal. Implementamos medidas de seguridad técnicas y organizativas para prevenir el acceso no autorizado, la divulgación, la alteración y la destrucción de la información que recopilamos.
    </p>

    <br />
    <h5>Compartir Información</h5>
    <p>
      No compartimos tu información personal con terceros sin tu consentimiento, excepto cuando sea necesario para procesar tu pedido o cumplir con requisitos legales.
    </p>

    <br />
    <h5>Cookies</h5>
    <p>
      Utilizamos cookies para mejorar tu experiencia de navegación en nuestro sitio web. Puedes gestionar las preferencias de cookies a través de la configuración de tu navegador.
    </p>

    <br />
    <h5>Derechos del Usuario</h5>
    <p>
      Tienes el derecho de acceder, rectificar, cancelar u oponerte al tratamiento de tus datos personales. Para ejercer estos derechos, ponte en contacto con Nayely Lara a través de lovelynailscostaverde@gmail.com.
    </p>

    <br />
    <h5>Cambios en la Política de Privacidad</h5>
    <p>
      Nos reservamos el derecho de actualizar esta Política de Privacidad en cualquier momento. Te recomendamos revisarla periódicamente para estar informado sobre cómo protegemos tu información.
    </p>
    <p>
      Al realizar una compra en LovelyNails, aceptas los términos y condiciones de esta Política de Privacidad.
    </p>

    <br />
    <p>
      <strong>Fecha de última actualización:</strong> 19 de enero de 2024
    </p>
  </div>
</template>

<script>
export default {
}
</script>
