<template lang="html">
  <div id="results-page">

    <section class="breadcrumb-section">
      <div class="container oversized-container">
        <span>SubCategorías</span>
        <span class="line">/</span>
        <span>{{ subcategory.name }}</span>
      </div>
    </section>

    <section class="container oversized-container products-section">
      <div class="row mb-3">
        <div class="col-12 col-s-title">
          <h2 class="title">{{ subcategory.name }}</h2>
        </div>
      </div>

      <div class="row align-items-start">

        <!-- Mobile filters -->
        <div class="col-12 col-filters-mobile">
          <div class="bg-filter-mv" v-if="showFilters == true" @click="showFilters = false"></div>
          <a class="btn-filters" @click="showFilters = !showFilters">
            <i class="fas fa-filter"></i><span>Ver filtros</span>
          </a>
        </div>

        <div class="col-lg col-filters" v-bind:class="{ 'show-filters': showFilters == true }">
          <h4 class="_title">
            <span>Filtros de Búsqueda</span>
            <a class="btn-hide" @click="showFilters = false"><i class="fas fa-chevron-left"></i></a>
          </h4>

          <b-form class="filters-container" @submit="onSubmitFilters">

            <div class="box-filters">
              <a class="btn-action"><i class="sy">-</i> Marca</a>

              <b-form-group class="box-filters-group" v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group
                  v-model="filtersForm.brands"
                  :aria-describedby="ariaDescribedby"
                  name="flavour-2">
                  <b-form-checkbox :value="br.id" v-for="(br,indx) in brandsOpcs" :key="'bra'+indx">{{br.name}}</b-form-checkbox>

                </b-form-checkbox-group>
              </b-form-group>
            </div>

            <div class="box-filters">
              <a class="btn-action"><i class="sy">-</i> Subcategoria</a>

              <b-form-group class="box-filters-group" v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group
                  v-model="filtersForm.subcategories"
                  :aria-describedby="ariaDescribedby"
                  name="flavour-2">
                  <b-form-checkbox :value="subc.id" v-for="(subc,indx) in subcategoriesOpcs" :key="'subca'+indx">{{subc.name}}</b-form-checkbox>

                </b-form-checkbox-group>
              </b-form-group>
            </div>
            <div class="box-filters">
              <a class="btn-action"><i class="sy">-</i> Precio</a>

              <div class="box-filters-group">
                <b-form-select class="c-filter-select" v-model="filtersForm.price">
                  <b-form-select-option :value="null">Cualquiera</b-form-select-option>
                  <b-form-select-option :value="800">$800 o menos</b-form-select-option>
                  <b-form-select-option :value="1499">$1,499 o menos</b-form-select-option>
                  <b-form-select-option :value="999999999999999999">$1,500 o más</b-form-select-option>
                </b-form-select>
              </div>
            </div>

          </b-form>
        </div>
        <!--  -->

        <!-- Resultados en columnas -->
        <div class="col-lg">
          <div class="row sample-products-wrapper xxl-4e" v-if="!isLoading">
            <div class="col-sm-6 col-md-6 col-xl-4 sample-product shad-prod" v-for="(product, index) in products" :key="index">
              <a class="outlined-box">
                <router-link class="fake-link" :to="'/producto/'+ $root._converToURL(product.name, product.id)"></router-link>

                <div class="box-image">
                  <div class="note" v-if="product.isNew">Nuevo</div>
                  <div class="out-of-stock" v-if="product.stock < 1">Agotado</div>

                  <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url('+product.imageUrl+')' }">
                    <img src="public/images/shared/empty.png" >
                  </div>
                </div>

                <div class="box-shot-descr">
                  <h6 class="brand">{{ product.sku }}</h6>
                  <h6 class="name">{{ product.name }}</h6>
                  <!-- <h6 class="price discount"><span>$ {{ product.price }}</span></h6> -->
                  <h6 class="price">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(product.price) }}</h6>
                </div>

                <cart-component :product="product"></cart-component>
              </a>
            </div>
          </div>

          <div class="row">
            <div class="col-12 text-center" v-if="!products.length && !isLoading">
              <h5>No se encontraron resultados</h5>
            </div>

            <div class="col-12" v-if="isLoading">
              <loading-component></loading-component>
            </div>
          </div>
        </div>
        <!--  -->

      </div>
    </section>

  </div>
</template>

<script>
import LoadingComponent from '../shared/loading-component.vue';
export default {
  components: {
    LoadingComponent
  },

  data(){
    return{
      isLoading: true,
      showFilters: false,

      filtersForm: {
        brands:[],
        subcategories:[],
        price:null,
      },

      products: [
        {
          "id": 1,
          "sku": "LUCC111",
          "name": "EJEMPLO DE NOMBRE DE PRODUCTO AQUÍ, CON MAXIMO DE 3 LINEAS",
          "price": "3050.00",
          "stock": 1,
          "imageUrl": "public/images/pages/products/product-1.jpg"
        },
        {
          "id": 2,
          "sku": "LUCC114",
          "name": "EJEMPLO DE NOMBRE DE PRODUCTO AQUÍ, CON MAXIMO DE 3 LINEAS",
          "price": "450.00",
          "stock": 3,
          "imageUrl": "public/images/pages/products/product-2.jpg",
        },
        {
          "id": 3,
          "sku": "LUCG100",
          "name": "EJEMPLO DE NOMBRE DE PRODUCTO AQUÍ, CON MAXIMO DE 3 LINEAS",
          "price": "289.00",
          "stock": 5,
          "imageUrl": "public/images/pages/products/product-3.jpg"
        },
        {
          "id": 4,
          "sku": "LUCH101",
          "name": "EJEMPLO DE NOMBRE DE PRODUCTO AQUÍ, CON MAXIMO DE 3 LINEAS",
          "price": "850.00",
          "stock": 2,
          "imageUrl": "public/images/pages/products/product-4.jpg"
        },
        {
          "id": 5,
          "sku": "LU23535017",
          "name": "EJEMPLO DE NOMBRE DE PRODUCTO AQUÍ, CON MAXIMO DE 3 LINEAS",
          "price": "1250.00",
          "stock": 9,
          "imageUrl": "public/images/pages/products/product-5.jpg"
        },
        {
          "id": 6,
          "sku": "LU23535017",
          "name": "EJEMPLO DE NOMBRE DE PRODUCTO AQUÍ, CON MAXIMO DE 3 LINEAS",
          "price": "1250.00",
          "stock": 9,
          "imageUrl": "public/images/pages/products/product-6.jpg"
        },
        {
          "id": 7,
          "sku": "LU23535017",
          "name": "EJEMPLO DE NOMBRE DE PRODUCTO AQUÍ, CON MAXIMO DE 3 LINEAS",
          "price": "1250.00",
          "stock": 9,
          "imageUrl": "public/images/pages/products/product-7.jpg"
        },
        {
          "id": 8,
          "sku": "LU23535017",
          "name": "EJEMPLO DE NOMBRE DE PRODUCTO AQUÍ, CON MAXIMO DE 3 LINEAS",
          "price": "1250.00",
          "stock": 9,
          "imageUrl": "public/images/pages/products/product-8.jpg"
        },
        {
          "id": 1,
          "sku": "LUCC111",
          "name": "EJEMPLO DE NOMBRE DE PRODUCTO AQUÍ, CON MAXIMO DE 3 LINEAS",
          "price": "3050.00",
          "stock": 1,
          "imageUrl": "public/images/pages/products/product-1.jpg"
        },
        {
          "id": 2,
          "sku": "LUCC114",
          "name": "EJEMPLO DE NOMBRE DE PRODUCTO AQUÍ, CON MAXIMO DE 3 LINEAS",
          "price": "450.00",
          "stock": 3,
          "imageUrl": "public/images/pages/products/product-2.jpg",
        },
        {
          "id": 3,
          "sku": "LUCG100",
          "name": "EJEMPLO DE NOMBRE DE PRODUCTO AQUÍ, CON MAXIMO DE 3 LINEAS",
          "price": "289.00",
          "stock": 5,
          "imageUrl": "public/images/pages/products/product-3.jpg"
        },
        {
          "id": 4,
          "sku": "LUCH101",
          "name": "EJEMPLO DE NOMBRE DE PRODUCTO AQUÍ, CON MAXIMO DE 3 LINEAS",
          "price": "850.00",
          "stock": 2,
          "imageUrl": "public/images/pages/products/product-4.jpg"
        },
      ],

      subcategory: [],
      subcategory_id:null,
      brandsOpcs:[
        { id: 1, name: 'Marca 1' },
        { id: 2, name: 'Marca 2' },
        { id: 3, name: 'Marca 3' },
        { id: 4, name: 'Marca 4' },
        { id: 5, name: 'Marca 5' },
        { id: 6, name: 'Marca 6' },
      ],
      subcategoriesOpcs:[
        { id: 1, name: 'Subcategoría 1' },
        { id: 2, name: 'Subcategoría 2' },
        { id: 3, name: 'Subcategoría 3' },
        { id: 4, name: 'Subcategoría 4' },
      ],
    }
  },

  watch: {
    "$route.params.id" : function(v){
      this.subcategory_id = this.$root._getURLID(this.$route.params.id);
      if(this.subcategory_id){
          this.products=[];
          this.getProductsSubCategory(this.subcategory_id);
      }
    },
    "filtersForm.brands" : function(v){
        this.getProductsSubCategory(this.subcategory_id);
    },
    "filtersForm.subcategories" : function(v){
        this.getProductsSubCategory(this.subcategory_id);
    },
    "filtersForm.price" : function(v){
        this.getProductsSubCategory(this.subcategory_id);
    },
  },

  methods: {
    onSubmitFilters(evt) {
      evt.preventDefault();
    },

    getProducts: function(){
      axios.get(tools.url('/api/products')).then((response)=>{
        this.products = response.data;
      }).catch((error)=>{
        console.log(error);
      })
    },

    getProductsSubCategory: function(category_id){
      this.isLoading = true;
      axios.post(tools.url('/api/products/subcategory/'+category_id),this.filtersForm).then((response) =>{
          this.products = response.data.products;
          this.subcategory = response.data.subcategory;
          this.isLoading = false;
      }).catch((error)=>{
        console.log(error);
      });
    },
    getBrandsOpcs(){
      axios.get(tools.url('/api/brandsOpcs/'+this.subcategory_id+'/s')).then((response)=>{
        this.brandsOpcs = response.data;
      }).catch((error)=>{
        console.log(error);
      });
    },
    getSubcategoriesOpcs(){
      axios.get(tools.url('/api/subcategoriesOpcs/'+this.subcategory_id+'/s')).then((response)=>{
        this.subcategoriesOpcs = response.data;
      }).catch((error)=>{
        console.log(error);
      });
    },
  },

  mounted(){
    setTimeout( ()=>{ this.isLoading = false; }, 2000); // ejemplo de "loading" despues de cargar

    // if(this.$route.params.id){
    //    this.subcategory_id = this.$root._getURLID(this.$route.params.id);
    //    this.getProductsSubCategory(this.subcategory_id);
    //    this.getBrandsOpcs();
    //  this.getSubcategoriesOpcs();
    // }
  },
}
</script>
