<template lang="html">
  <b-form @submit="onSubmit">
    <div class="box-quantity">
      <a class="q-btn minus" @click="changeQuantity('-')">-</a>
      <b-form-input
        v-model="formCart.quantity"
        type="number"
        required
      ></b-form-input>
      <a class="q-btn plus" @click="changeQuantity('+')">+</a>
    </div>

    <b-button type="submit" class="t-150 btn-cart"><i class="fa-regular fa-cart-shopping"></i> Añadir al carrito</b-button>
  </b-form>
</template>

<script>
export default {
  props: ['product'],

  data() {
    return {
      formCart: {
        quantity: 1,
      }
    }
  },

  methods: {
    changeQuantity(action) {
      if(action == '+') {
        this.formCart.quantity++;
      }
      else {
        this.formCart.quantity = (this.formCart.quantity > 1) ? this.formCart.quantity-= 1 : 1;
      }
    },

    onSubmit(event) {
      event.preventDefault();
      this.formCart.quantity = 1;
      alert('Producto agregado al carrito');
    }
  }
}
</script>
