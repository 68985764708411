<template>
	<footer id="footer">

		<section class="newsletter-section">
			<div class="container oversized-container">
				<div class="row align-items-center row-top">
	        <div class="col-lg-5 col-left col-newsletter">
	          <b-form @submit="onSubmit">
	            <b-form-input
	              v-model="form.email"
	              type="email"
	              placeholder="Escribe tu correo..."
	              required
	            ></b-form-input>

	            <b-button type="submit" class="btn-submit">Inscribirse</b-button>
	          </b-form>
	        </div>

	        <div class="col-lg col-middle">
	          <h6>Sé el primero en enterarte</h6>
	          <h4>Regístrate a nuestro newsletter</h4>
	        </div>

	        <div class="col-lg row mx-0 col-right">
	          <div class="box-networks">
							<a class="t-150 btn-network fb" target="_blank" href="https://facebook.com/NayelyLaraaa"><i class="fab fa-facebook-f"></i></a>
							<a class="t-150 btn-network ig" target="_blank" href="https://instagram.com/lovely_nails_oficial/"><i class="fab fa-instagram"></i></a>
	            <a class="t-150 btn-network ig" target="_blank" href="https://clic2.chat/f0d0b7"><i class="fa-brands fa-whatsapp"></i></a>
	            <!-- <a class="t-150 btn-network tw" href="#"><i class="fab fa-twitter"></i></a> -->
	            <!-- <a class="t-150 btn-network lk" href="#"><i class="fab fa-linkedin-in"></i></a> -->
	          </div>
	          <router-link class="logo" to="/"><img src="public/images/logo.png"></router-link>
	        </div>
	      </div>
			</div>
		</section>

		<section class="menu-section">
			<div class="container oversized-container">
				<hr />

				<p class="menu">
					<router-link to="/aviso-de-privacidad">PRIVACIDAD</router-link>
					<router-link to="/cambios-y-devoluciones">CAMBIOS Y DEVOLUCIONES</router-link>
					<router-link to="/politica-de-seguridad">SEGURIDAD</router-link>
					<a target="_blank" href="https://clic2.chat/f0d0b7">AYUDA</a>
					<router-link to="/contacto">CONTACTO</router-link>
				</p>
			</div>
		</section>

	</footer>
</template>

<script type="text/javascript">
export default {
	data(){
    return{
      currentYear: 0,

      form: {
        email: null
      },
      info: {
        email:null,
        contact:null,
        address:null,
      },
    }
  },

  methods:{
    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-right',
        appendToast: true
      });
    },

    onSubmit(evt) {
      evt.preventDefault();
      var r = confirm("¿Deseas regístrate en newsletter? ");
      if (r == true) {
        axios.post(tools.url("/api/newsletter"), this.form).then((response)=>{
          this.makeToast('default', response.data.response, 'Correo registrado');
          this.form = {
            name: null,
            email: null,
            msg: null,
            check: null
          };
        }).catch( (error)=> {
          // console.log(error.response);
          let errors = error.response.data;
          // console.log(errors);
          this.makeToast('danger', error.response.data.name, 'Error');
          this.makeToast('danger', error.response.data.email, 'Error');
          this.makeToast('danger', error.response.data.phone, 'Error');
          this.makeToast('danger', error.response.data.msg, 'Error');
        });
      }
    },
  },

  beforeMount(){
    this.currentYear = new Date().getFullYear();
  }
}
</script>
