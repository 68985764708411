<template lang="html">
  <div id="dealers-page">
    <section class="container main-section">
      <div class="row">
        <div class="col-12 col-top">
          <h2 class="title">Distribuidores</h2>
        </div>

        <div class="col-lg-3 col-filters">
          <b-form @submit="onSubmit">
            <b-form-group class="custom-f-group-s1" label="Distribuidor">
              <b-form-input
                v-model="form.email"
                type="text"
                placeholder="Buscar distribuidor"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group class="mt-3 mt-lg-4 custom-f-group-s1" label="Estado">
              <b-form-select v-model="form.state">
                <b-form-select-option :value="null">Todos los estados</b-form-select-option>
                <b-form-select-option value="1">Aguascalientes</b-form-select-option>
                <b-form-select-option value="2">CDMX</b-form-select-option>
                <b-form-select-option value="3">Guanajuato</b-form-select-option>
                <b-form-select-option value="4">Jalisco</b-form-select-option>
                <b-form-select-option value="5">Zacatecas</b-form-select-option>
              </b-form-select>
            </b-form-group>

            <b-form-group class="mt-3 mt-lg-4 custom-f-group-s1" label="Estado">
              <b-form-select v-model="form.city">
                <b-form-select-option :value="null">Todos las ciudades</b-form-select-option>
                <b-form-select-option value="3">Ameca</b-form-select-option>
                <b-form-select-option value="1">Guadalajara</b-form-select-option>
                <b-form-select-option value="4">Mascota</b-form-select-option>
                <b-form-select-option value="5">Tonala</b-form-select-option>
                <b-form-select-option value="2">Zapopan</b-form-select-option>
              </b-form-select>
            </b-form-group>

            <p class="mt-3 mt-lg-4">
              <b-button type="submit" class="btn-search">Buscar</b-button>
            </p>
          </b-form>
        </div>

        <div class="col-lg-9 col-results">
          <div class="box-table">
            <div class="row row-top">
              <div class="col col-cell c-0"></div>
              <div class="col col-cell c-1">Distribuidor</div>
              <div class="col col-cell c-2">Ciudad del Distribuidor</div>
              <div class="col col-cell c-3">Estado del Distribuidor</div>
              <div class="col col-cell c-4">Teléfono del Distribuidor</div>
            </div>

            <div class="row row-body" v-for="x in 30">
              <div class="col col-cell c-0">
                <img src="public/images/pages/dealers/logo.jpg">
              </div>
              <div class="col col-cell c-1">
                <a>Jc Supply</a>
              </div>
              <div class="col col-cell c-2">TECATE</div>
              <div class="col col-cell c-3">Baja California</div>
              <div class="col col-cell c-4">
                <a class="t-150 btn-whatsapp"><i class="fa-brands fa-whatsapp"></i> WhatsApp</a>
              </div>
            </div>
          </div>

          <div class="box-pagination-s1">
            <nav aria-label="navigation">
              <ul class="pagination">
                <li class="page-item">
                  <a class="page-link" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                    <span class="sr-only">Previous</span>
                  </a>
                </li>
                <li class="page-item"><a class="page-link">1</a></li>
                <li class="page-item"><a class="page-link">2</a></li>
                <li class="page-item"><a class="page-link">3</a></li>
                <li class="page-item">
                  <a class="page-link" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                    <span class="sr-only">Next</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>

        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        state: null,
        city: null,
      },

      foods: [{ text: 'Select One', value: null }, 'Carrots', 'Beans', 'Tomatoes', 'Corn'],
      show: true
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
    },
  }

}
</script>
