<template lang="html">
  <div class="container" id="text-page">
    <h1 class="page-title txt-black">Política de Seguridad Online</h1>

    <p class="mb-2">
      Lovely Nails, toma la seguridad de nuestros clientes como máxima prioridad. Nuestra tienda online, desarrollada usando tecnologías web de ultima generación, está respaldada por rigurosas medidas para garantizar la confidencialidad, integridad y disponibilidad de la información. A continuación, se detallan los principios y procedimientos de nuestra política de seguridad:
    </p>

    <br />
    <h5>SSL DV (Certificado de Seguridad)</h5>
    <p>
      La comunicación entre el navegador del usuario y nuestra tienda online está cifrada mediante un Certificado SSL DV (Domain Validated), asegurando la privacidad de la información transmitida.
    </p>

    <br />
    <h5>Inmunify AV+ y Cumplimiento con OWASP</h5>
    <p>
      Utilizamos Inmunify AV+ para proteger nuestra tienda contra amenazas cibernéticas, y nos adherimos a las directrices establecidas por la Open Web Application Security Project (OWASP) para mitigar vulnerabilidades y fortalecer la seguridad de nuestras aplicaciones web.
    </p>

    <br />
    <h5>Protección de Datos del Cliente</h5>
    <p>
      La información personal y financiera de nuestros clientes está resguardada con protocolos de seguridad robustos. Empleamos prácticas de cifrado avanzadas y gestión segura de contraseñas.
    </p>

    <br />
    <h5>Operador de Pagos y Cumplimiento PCI DSS</h5>
    <p>
      Nuestro operador de pagos, Mercado Pago, cumple con los estándares de la Payment Card Industry Data Security Standard (PCI DSS), garantizando la seguridad de los datos de tarjetas de crédito durante las transacciones.
    </p>

    <br />
    <h5>Actualizaciones y Parches</h5>
    <p>
      Mantenemos nuestra plataforma actualizada con las últimas versiones de nuestros sistemas, aplicando parches de seguridad de manera regular para proteger contra vulnerabilidades conocidas.
    </p>

    <br />
    <h5>Monitoreo Continuo</h5>
    <p>
      Implementamos un sistema de monitoreo continuo para detectar y responder rápidamente a cualquier actividad sospechosa. Esto incluye la supervisión de registros, análisis de tráfico y alertas en tiempo real.
    </p>

    <br />
    <h5>Política de Contraseñas y Acceso Restringido</h5>
    <p>
      Se implementan políticas de contraseñas robustas, y el acceso a la tienda está restringido a personal autorizado. Se asignan permisos de manera precisa para limitar el acceso a la información según las responsabilidades del usuario.
    </p>

    <br />
    <h5>Respaldo de Datos</h5>
    <p>
      Realizamos copias de seguridad periódicas de la información crítica para asegurar la disponibilidad de los datos en caso de eventos imprevistos.
    </p>
    <p>
      Esta política de seguridad refleja nuestro compromiso con la protección de la información de nuestros clientes. Nos esforzamos por mantener un entorno seguro y confiable para garantizar una experiencia de compra en línea sin preocupaciones en Lovely Nails.
    </p>
  </div>
</template>

<script>
export default {
}
</script>
